<template>
    <div class="tab-pane fade show active row row-cols-1 row-cols-md-4 mb-3" id="publish" role="tabpanel"
            aria-labelledby="publish-tab">
            <div class="col div">
                <div class="card mb-4 rounded-3 shadow-sm c-height">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Community
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">$0
                            <small class="text-body-secondary fw-light">/mo</small>
                        </h1>
                        <button type="button" class="w-100 btn btn-outline-success">Sign up for free</button>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Includes:
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Educational development
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Curriculum for development
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Learn as you build
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Test your work online
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col div">
                <div class="card mb-4 rounded-3 shadow-sm c-height">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Basic
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">$6
                            <small class="text-body-secondary fw-light">/mo</small>
                        </h1>
                        <button type="button" class="w-100 btn btn-outline-success">Sign up for free</button>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Everything in community and:
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>1 Project
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Hosting
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>10GB Storage
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Free domain name
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>SEO integration
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Website Analytics
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Priority support
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col div">
                <div class="card mb-4 rounded-3 shadow-sm c-height">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Developer
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">$8
                            <small class="text-body-secondary fw-light">/mo</small>
                        </h1>
                        <button type="button" class="w-100 btn btn-outline-success">Sign up for free</button>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Everything in Basic and:
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i> 2 Projects
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>12GB Storage
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Connect to a CMS
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Build dynamic web application
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Sublet/share project
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Version history
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col div">
                <div class="card mb-4 rounded-3 shadow-sm c-height">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Enterprise
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">Custom
                        </h1>
                        <button type="button" class="w-100 btn btn-outline-success">Contact Us</button>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Everything in developer and:
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>20GB+ Storage
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Total web management
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Enterprise package
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>5+ Members
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Technical onboarding
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
<div class="tab-pane fade row row-cols-1 row-cols-md-4 mb-3" id="export" role="tabpanel"
            aria-labelledby="export-tab">
            <div class="col div">
                <div class="card mb-4 rounded-3 shadow-sm c-height">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Community
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">$0
                            <small class="text-body-secondary fw-light">/mo</small>
                        </h1>
                        <button type="button" class="w-100 btn btn-outline-success">Sign up for free</button>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Includes:
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Educational Materials
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Curriculum for development
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Learn as you build
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Test your work online
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col div">
                <div class="card mb-4 rounded-3 shadow-sm c-height">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Basic
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">$4
                            <small class="text-body-secondary fw-light">/mo</small>
                        </h1>
                        <button type="button" class="w-100 btn btn-outline-success">Sign up for free</button>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Everything in community and:
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>1 Project
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Artifacts export
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>SEO integration
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Website Analytics
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Priority support
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col div">
                <div class="card mb-4 rounded-3 shadow-sm c-height">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Developer
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">$6
                            <small class="text-body-secondary fw-light">/mo</small>
                        </h1>
                        <button type="button" class="w-100 btn btn-outline-success">Sign up for free</button>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Everything in Basic and:
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i> 2 Projects
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Connect to a CMS
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Build dynamic web application
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Sublet/share project
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Version history
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col div">
                <div class="card mb-4 rounded-3 shadow-sm c-height">
                    <div class="card-header py-3">
                        <h4 class="my-0 fw-normal">Enterprise
                        </h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">Custom
                        </h1>
                        <button type="button" class="w-100 btn btn-outline-success">Contact Us</button>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>Everything in developer and:
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>10+ Projects
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Custom storage
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Total web management
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Enterprise package
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>5+ Members
                            </li>
                            <li><i class="bi bi-check2 fonttick"></i>Technical onboarding
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  name: 'PricingBar'
}
</script>